import { ReactComponent as SellerIcon } from './seller.svg';
import { ReactComponent as DiscussionIcon } from './discussion.svg';
import { ReactComponent as BlogIcon } from './blog.svg';
import { ReactComponent as SupportIcon } from './support.svg';
import { ReactComponent as TwitterIcon } from './twitter.svg';
import { ReactComponent as YoutubeIcon } from './youtube.svg';
import { ReactComponent as LinkedinIcon } from './linkedin.svg';
import { ReactComponent as PinterestIcon } from './pinterest.svg';
import { ReactComponent as DiscordIcon } from './discord.svg';
import { ReactComponent as RedditIcon } from './reddit.svg';
import { ReactComponent as BlueskyIcon } from './bluesky.svg';
export default {
	socialPart: {
		socials: {
			discord: {
				link: 'https://discord.gg/5rS6Tvd',
				title: 'Discord',
				icon: DiscordIcon,
			},
			twitter: {
				link: 'https://x.com/hostjane',
				title: 'X',
				icon: TwitterIcon,
			},
			reddit: {
				link: 'https://www.reddit.com/r/HostJane',
				title: 'Reddit',
				icon: RedditIcon,
			},
			youtube: {
				link: 'https://www.youtube.com/HostJane',
				title: 'YouTube',
				icon: YoutubeIcon,
			},
			pinterest: {
				link: 'https://www.pinterest.com/hostjane/',
				title: 'Pinterest',
				icon: PinterestIcon,
			},
			linkedIn: {
				link: 'https://linkedin.com/company/hostjane',
				title: 'LinkedIn',
				icon: LinkedinIcon,
			},
			bluesky: {
				link: 'https://bsky.app/profile/hostjane.com',
				title: 'Bluesky',
				icon: BlueskyIcon,
			},
		},
		description:
			'Find a pool of experts at affordable prices or buy secure web hosting to launch your website in minutes!',
	},
	footLinks: {
		jip: {
			title: '#JaneIsPowerful',
			link: 'https://www.hostjane.com/about-jane/',
			internal: false,
		},
		others: [
			{
				link: 'https://www.hostjane.com/legal/tos/',
				internal: false,
				title: 'Site Terms',
			},
			{
				link: 'https://www.hostjane.com/legal/ihra-antisemitism/',
				internal: false,
				title: 'We Stand Against Racism',
			},
			{
				link: 'https://www.hostjane.com/legal/privacy/',
				internal: false,
				title: 'Privacy',
			},
			{
				link: 'https://www.hostjane.com/legal/cookie-policy/',
				internal: false,
				title: 'Cookies',
			},
			{
				link: 'https://www.hostjane.com/sitemap/',
				internal: false,
				title: 'Sitemap',
			},
		],
		copyright: {
			link: 'https://www.hostjane.com',
			internal: false,
			title: `© ${new Date().getFullYear()} HostJane, Inc.`,
		},
	},
	columns: [
		{
			title: 'Marketplace',
			items: [
				{
					title: 'All Services',
					internal: false,
					link: 'https://www.hostjane.com/marketplace',
				},
				{
					title: 'Web / Mobile / Tech',
					internal: false,
					link:
						'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers',
				},
				{
					title: 'Design / Art & Video',
					internal: false,
					link:
						'https://www.hostjane.com/marketplace/categories/design-art-video-voice',
				},
				{
					title: 'Bookings',
					internal: false,
					link:
						'https://www.hostjane.com/marketplace/categories/online-tutors',
				},
				{
					title: 'Writing / Translation',
					internal: false,
					link:
						'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers',
				},
				{
					title: 'Business / Admin',
					internal: false,
					link:
						'https://www.hostjane.com/marketplace/categories/business-admin-support',
				},
				{
					title: 'Browse Skills',
					internal: false,
					link: 'https://www.hostjane.com/marketplace/skills',
				},
				{
					title: 'Marketplace Rules',
					internal: false,
					link: 'https://www.hostjane.com/legal/marketplace-terms',
				},
			],
		},
		{
			title: 'VPS & Cloud Hosting',
			items: [
				{
					title: 'VPS for Business',
					internal: true,
					link: '/vps-hosting/',
				},
				{
					title: 'WordPress Hosting',
					internal: true,
					link: '/wordpress/',
				},
				{
					title: 'Cloud Hosting',
					internal: true,
					link: '/cloud/',
				},
				{
					title: 'Windows Hosting',
					internal: true,
					link: '/windows/',
				},
				{
					title: 'Service Level Agreement',
					internal: false,
					link: 'https://www.hostjane.com/legal/sla',
				},
				{
					title: 'Hosting Terms',
					internal: false,
					link: 'https://www.hostjane.com/legal/hosting-terms',
				},
				{
					title: 'Contact Support',
					internal: false,
					link: 'https://www.hostjane.com/marketplace/contact',
				},
			],
		},
		{
			title: 'Help',
			items: [
				{
					title: 'Help Center',
					internal: false,
					link: 'https://help.hostjane.com/',
				},
				{
					title: 'Telegram Messages',
					internal: false,
					link: 'https://t.me/hostjanesupport',
				},
				{
					title: 'Telegram Channel',
					internal: false,
					link: 'https://t.me/hostjanecom',
				},
				{
					title: 'WordPress Plugin',
					internal: false,
					link:
						'https://wordpress.org/plugins/tip-my-work-hostjane-payments/',
				},
				{
					title: 'WP Plugin Guide',
					internal: false,
					link: 'https://www.hostjane.com/wordpress',
				},
				{
					title: 'Network Status',
					internal: false,
					link: 'https://www.hostjane.com/status',
				},
				{
					title: 'Disputes Center',
					internal: false,
					link: 'https://www.hostjane.com/marketplace/disputes/',
				},
				{
					title: 'Acceptable Use Policy',
					internal: false,
					link: 'https://www.hostjane.com/legal/use-policy',
				},
				{
					title: 'Terms of Service',
					internal: false,
					link: 'https://www.hostjane.com/legal/tos/',
				},
				{
					title: 'Contact Support',
					internal: false,
					link: 'https://www.hostjane.com/marketplace/contact',
				},
			],
		},
		{
			title: 'Sell Your Skills',
			items: [
				{
					title: 'Become a Seller',
					internal: false,
					link: 'https://www.hostjane.com/sell',
				},
				{
					title: 'Listing Center',
					internal: false,
					link: 'https://www.hostjane.com/marketplace/listings',
				},
				{
					title: 'Order Center',
					internal: false,
					link: 'https://www.hostjane.com/marketplace/orders',
				},
				{
					title: 'Account Settings',
					internal: false,
					link: 'https://www.hostjane.com/marketplace/settings',
				},
				{
					title: 'Transactions / Invoices',
					internal: false,
					link: 'https://www.hostjane.com/marketplace/reporting',
				},
				{
					title: 'Payout Preferences',
					internal: false,
					link:
						'https://www.hostjane.com/marketplace/settings/payouts',
				},
				{
					title: 'Working on HostJane',
					internal: false,
					link: 'https://help.hostjane.com/sellers/',
				},
			],
		},
		{
			title: 'Keep money moving',
			mobileTitle: 'Keep money moving',
			items: [
				{
					title: 'Mission + Values',
					internal: false,
					link: 'https://www.hostjane.com/about',
				},
				{
					title: 'Bluesky Stories',
					internal: false,
					link: 'https://bsky.app/profile/hostjane.com',
				},
				{
					title: 'Better Tools',
					internal: false,
					link:
						'https://help.hostjane.com/getting-started/what-are-boards/',
				},
				{
					title: 'Ukraine War Album',
					internal: false,
					link: 'https://www.hostjane.com/hostjaneforukraine/',
				},
				{
					title: 'Official X',
					internal: false,
					link: 'https://x.com/hostjane',
				},
				{
					title: 'Celebs Promote',
					internal: false,
					link:
						'https://www.youtube.com/watch?v=1qDe50faq0g&list=PLFlfImvkRB6nhw6Qt5EgXbQrdEz6dP4ln',
				},
				{
					title: 'TikTok Shorts',
					internal: false,
					link: 'https://www.tiktok.com/@hostjane',
				},
				{
					title: 'Snapchat',
					internal: false,
					link:
						'https://www.snapchat.com/add/hostjane?share_id=RkJFRUVF&locale=en_US',
				},
				{
					title: 'Discord Jobs',
					internal: false,
					link: 'https://discord.gg/5rS6Tvd',
				},
				{
					title: 'HJ Subreddit',
					internal: false,
					link: 'https://www.reddit.com/r/HostJane/',
				},
				{
					title: 'Tumblr Tags',
					internal: false,
					link: 'https://hostjane.tumblr.com/',
				},
			],
		},
	],
	connect: {
		title: 'Managed Hosting',
		withIcons: [
			{
				icon: SellerIcon,
				title: 'WHM/cPanel VPS',
				link: 'https://cloud.hostjane.com/vps/?appType=0&app=0',
				internal: false,
			},
			{
				icon: DiscussionIcon,
				title: 'DirectAdmin VPS',
				link: 'https://cloud.hostjane.com/vps/?appType=0&app=1',
				internal: false,
			},
			{
				icon: BlogIcon,
				title: 'Plesk VPS',
				link: 'https://cloud.hostjane.com/vps/?appType=0&app=2',
				internal: false,
			},
			{
				icon: SupportIcon,
				title: 'Managed Cloud',
				link: 'https://cloud.hostjane.com/cloud/',
				internal: false,
			},
		],
		smallOne: [
			{
				title: 'Quick Start Guides',
				link: 'https://help.hostjane.com/',
				internal: false,
			},
			{
				title: 'Contact Support',
				link: 'https://www.hostjane.com/marketplace/contact',
				internal: false,
			},
		],
		smallTwo: [
			
			{
				title: 'Login to Hosting',
				link: 'https://cloud.hostjane.com/hosting/login',
				internal: false,
			},
		],
	},
};
