export default {
	title: 'Take the next step',
	sections: [
		{
			title: 'Work with a trusted seller',
			buttons: [
				{
					title: 'Search talent',
					internal: false,
					link: 'https://www.hostjane.com/marketplace',
					type: 'primary',
				},
				{
					title: 'Sign in',
					internal: false,
					link: 'https://www.hostjane.com/marketplace/login',
					type: 'secondary',
				},
			],
		},
		{
			title: 'Move to HostJane',
			buttons: [
				{
					title: 'WordPress Hosting',
					internal: true,
					link: '/wordpress/',
					type: 'primary',
				},
				{
					title: 'Managed VPS',
					internal: true,
					link: '/vps-hosting/',
					type: 'secondary',
				},
			],
		},
	],
	list: [
		{
			label: 'Get free quotes',
			title: 'Talk to freelancers',
			link:
				'https://www.hostjane.com/marketplace/categories',
			internal: false,
		},
		{
			label: 'Create an income stream',
			title: 'Become a seller',
			link: 'https://www.hostjane.com/sell',
			internal: false,
		},
		{
			label: 'Continue browsing',
			title: 'See all solutions',
			link: 'https://www.hostjane.com/marketplace/skills',
			internal: false,
		},
	],
	footer:
		'HostJane serves as a limited authorized payment collection agent for Sellers.',
};
